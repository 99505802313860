.signup {
    background-image: url('/site/themes/reviewtheme/img/BlueBkgMBFlip.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: $white;
    text-align: center;
    min-height: 220px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include for-tablet-landscape-up {
        background-image: url('/site/themes/reviewtheme/img/BlueDTFlip.png');
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $gray;
        font-style: italic;
        font-weight: 200;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $gray;
        font-style: italic;
        font-weight: 200;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: $gray;
        font-style: italic;
        font-weight: 200;
    }
}

.signup h2 {
    padding: 1.5em 1em 2em;
    font-weight: 200;
    font-size: 1.3em;
    color: $white;
}

.signup p {
    font-weight: 200;
    font-style: italic;
    font-size: .8em;
    letter-spacing: .1em;
    padding-bottom: 2em;
    color: $white;
}

.signup-line {
    display: block;
    height: 1px;
    background-color: $gray;
    width: 100%;
    margin-bottom: 1em;

    @include for-tablet-landscape-up {
        display: none;
    }
}

.signup .input-group {
    width: 80%;
    margin: 0 auto;
    max-width: 900px;

    @include for-tablet-landscape-up {
        display: grid;
        grid-template-columns: 6fr 6em;
        grid-template-rows: auto 2px;
        margin-bottom: 3em;
    }
}

.signup input[type=email] {
    background-color: rgba(0,0,0,0);
    border-width: 0px;
    color: $white;
    font-weight: 200;
    width: 100%;
    line-height: 2em;
    outline: none;
    caret-color: $grey;
}

#btnSignUp {
    // background: $red;
    // border: 1px solid $red;
    // border-radius: 50px;
    // color: $white;
    // font-size: .8em;
    // font-weight: 400;
    // letter-spacing: .2em;
    // text-transform: uppercase;
    // text-align: center;
    // width: 200px;
    // height: 50px;
    // margin: 2em auto 2em;
    // cursor: pointer;

    @include for-tablet-landscape-up {
        display: none;
    }
}

#wideBtnSignup {
    display: none;
    background-color: rgba(0,0,0,0);
    border: none;
    color: $red;
    font-weight: 200;
    font-size: .9em;
    text-transform: uppercase;
    letter-spacing: .1em;
    line-height: 2em;
    width: auto;
    cursor: pointer;

    @include for-tablet-landscape-up {
        display: inline-block;
    }
}

.red-submit {
    display: flex;
    align-items: flex-end;
    &:hover {
        opacity: .7;
        cursor: pointer;
    }
}

.arrow-icon {
    display: none;
    width: 16px;
    height: auto;
    transform: scaleX(-1);
    align-self: center;

    @include for-tablet-landscape-up {
        display: inline-block;
    }
}
.arrow-animate {
    animation: arrow-bounce 1s infinite;
}

@keyframes arrow-bounce {
  0%   { padding-left: 0px; }
  50%   { margin-left: 15px; }
  100%   { padding-left: 0px; }
}


.wide-line {
    display: none;
    background-color: $grey;
    min-height: 1px;

    @include for-tablet-landscape-up {
        display: block;
    }
}

.success-message {
    display:none;
    font-style: italic;
    padding: 0 2em;
    h2 {
        color: $red;
    }
    p {
        color: $white;
    }
}

.error-message {
    display:block;
    color: $red;
    text-align: center;
    margin: 0 auto 1em;
}
