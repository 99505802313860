.below {
    background-color: $white;
    margin: 0;
    padding:0;
}
.below-wrap{
    max-width: 900px;
    margin: 0 auto;
    padding: 1em;
    h2{
        padding: 1em 0;
        font-weight: bold;
        font-size: 1.7em;
    }
    h3{
        text-transform: none;
        letter-spacing: 0.035em;
        padding: 1em 0;
    }
    p{
        padding: 1em 0;
        margin: 0 auto;
    }
}
