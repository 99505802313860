.stats {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 2px auto 2px auto;
    width: 80%;
    margin: 0 auto;
}

.stat {
    margin: 2em auto;
}

.line1, .line2 {
    background-color: $gray;
}

.stats h2 {
    font-size: 5em;
    font-weight: 300;
    color: $light_blue;
}

.stats h3 {
    text-transform: uppercase;
    font-weight: 400;
}
