hr {
    color: $grey;
    background-color: $grey;
}

.horizontal-line {
    padding: 20px 0;
}

.ba-holder, .sidebar-holder {
    text-align: center;
    margin: 30px 0;
}


.two-column {
    display: grid;
    grid-template-columns: 1fr;
    margin: 20px auto;

    @include for-tablet-landscape-up {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
    }

}

.two-column .a, .two-column .b {
    padding: 15px;

    p img {
        object-fit: cover;
        width: 100%;
    }
}

.image-caption {
    display: block;
    color: #999;
    font-style: italic;
    padding: 10px 0 0;
}

.full-width-image {
    margin: 2em 0;
}
.full-width-image img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
}

.bullet-list {
    display: grid;
    grid-template-columns: 23px 1fr;
    align-items: start;
    grid-row-gap: 1em;
    margin: 1em 0;

    p {
        margin: 0;
        padding: 0 1em;
        text-align: left;
    }
}

.bullet {
    object-fit: cover;
    width: 100%;
    max-height: 100%;
}

.html-block {
    display: block;
}

.big-quote {
    margin: 40px auto;
    text-align: center;
}

.big-quote .quote-text {
    color: $light_blue;
    font-style: italic;
    font-size: 26px;
    line-height: 38px;
    margin-bottom: 20px;
    padding: 0 2em;
}

.big-quote .quote-author {
    text-transform: uppercase;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: .05em;
}

.ba-holder.ba-300-250 {
    min-height: 250px;
    min-width: 300px;
    padding: 0;
    @include for-tablet-landscape-up {
        display: none;
    }
}

.ba-holder.ba-300-600 {
    min-height: 600px;
    min-width: 300px;
    padding: 0;
    margin: 0;
}

.ba-holder.ba-728-90 {
    min-height: 90px;
    min-width: 728px;
    padding: 0;
    display: none;
    @include for-tablet-landscape-up {
        display: block;
    }
}

.ba-holder.ba-160-600 {
    min-height: 600px;
    min-width: 160px;
    padding: 0;
    margin: 0;
}

.sidebar-holder.ba-300-600 {
    min-height: 600px;
    min-width: 300px;
    padding: 0;
    margin: 0;
}

