.review-list-body {
    background-color: $off_white;
    padding-top: 1em;
    padding-bottom: 1em;

    p, h2 {
        color: $blue;
    }
}

.ban-text {
    position: absolute;
    top:0;
    left:0;
    font-size: 1em;
    color: #E5E5E5;
    text-align: center;
    width: 100%;
    height: 100%;
}
.ban-text h3 {
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    color: $white;
    margin:0;
    padding:0;
}

.img-container {
    position: relative;
    margin: 0 auto 1.5em;
    width:200px;
}

.img-container img {
    display: block;
}

.hoverlogo:hover {
    transition: opacity .2s linear;
    opacity: .7;
    cursor: pointer;
}

.review-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $white;
    text-align: center;
    margin: 1em 1em 2em;
    padding: 1em 1.5em;
    box-shadow: 0px 0px 10px 3px $grey;
    @include for-tablet-landscape-up {
        display:none;
    }

    h3 {
        display: inline;
        color: $black;
        font-size: .9em;
        font-weight: 700;
        letter-spacing: .1em;
    }
    .rating-area {
        margin: 1em auto;
    }
    .num-rating {
        color: $red;
    }

    .button {
        display: block;
        margin: 2em auto;
        text-decoration: none;
    }

    p {
        margin: 1em auto;
    }
}

.red {
    stroke: $red;
    fill: $red;
}

.expanded-card-content {
    max-height: auto;
    overflow: hidden;
    transition: max-height .2s linear;
    .card-header {
        display: grid;
        grid-template-columns: 1fr 1px 1fr;
        grid-template-rows: 2em auto 1px;
        margin-bottom: 2em;
    }

    .num-rating{
        font-size: 3em;
        font-weight: 300;
        letter-spacing: .03em;
        align-self: center;
    }

    h3 {
        color: $dark_grey;
        font-weight: 400;
    }

    .review-label {
        margin: 1.5em auto;
        display: block;
    }
}

.card-vert-line {
    background-color: $grey;
    width: 100%;
    height: auto;
}
.card-horizontal-line {
    background-color: $grey;
    width: 100%;
    height: 1px;
    margin-top: 1em;
}

.expanded-card-logo {
    width: auto;
    max-width: 90%;
    height: auto;
    margin: 0 auto;
    align-self: center;
}


.downarrow {
    position: relative;
    width: 35px;
    height: auto;
}

.collapsible-content {
    max-height: 0;
    overflow: hidden;
}

.arrow-rotate {
    transform: rotate(180deg);
}

.card-logo {
    position: relative;
    display: block;
    top: 1em;
    margin: 0 auto;
    max-width: 200px;
}

.bullet-grid.bg-card {
    margin: 2em 0;
    width: 100%;
    justify-content: start;
    align-items: start;
    p {
        font-weight: 400;
        margin: 0;
        text-align: left;
        padding: 0 1em;
    }
}

.read-full a {
    font-style: italic;
    color: $red
}

.h-line {
    height: 1px;
    background-color: $grey;
    margin: 2em auto;
}

.offer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    justify-self: center;
    align-self: center;
    flex: 1;
}

.offer-headline {
    font-weight: 400;
}

.offer-subtext {
    font-style: italic;
}

//Desktop

.review-card-DT {
    display: none;
    background-color: $white;
    grid-template-columns: 1fr 2px 2fr 2px 1fr;
    margin: 1em auto 2em;
    max-width: 900px;
    padding: 2em;
    box-shadow: 0px 0px 10px 3px $grey;

    h3 {
        color: $dark_grey;
        font-size: .9em;
        font-weight: 400;
    }

    @include for-tablet-landscape-up {
        display:grid;
    }
}

.brand-box {
    display:flex;
    flex-direction: column;
    margin-right: 2em;
    align-items: center;
    justify-content: center;

    .num-rating {
        color: $red;
        font-size: 3em;
        font-weight: 300;
        letter-spacing: .03em;
        align-self: start;
    }
}

.thebrand {
    text-align: center;
}

.brand-box a {
    display: block;
    text-align: center;
    align-self: start;
    margin: 0 auto 2em;

    img {
        object-fit: contain;
        max-width: 200px;
    }
}

.card-brand {
    padding-bottom: 2em;
}

.review-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 0 2em;

    .card-headline {
        margin-top: 1em;
    }
}

.deal-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-left: 2em;

    h3 {
        text-align: center;
    }
    h2 {

        text-align: center;
    }
    p {
        text-align: center;
        margin-top: 1.5em;
    }
    .dealbutton {
        align-self: flex-end;
        margin-top: 0;
    }
}

.starbox {
    text-align: center;
    margin-top: 3em;
}

.stay-put {
    display: inline-block;
    width: 25px;
    text-align: left;
}
