.featured-categories {
    position: relative;
    background-color: $off_white;
    color: $dark_blue;
}

.featured-categories > h2 {
    padding: 1.3em;
}

.featured-categories img {
    width: 80%;
    height:auto;
}

.category-grid {
    align-content: center;
    justify-content: center;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto;
    padding: 20px;
}

.topic-card {
    background-color: $white;
    height: 180px;
}

.topic-card-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    h2 {
        font-size: 1em;
        font-weight: 500;
        letter-spacing: .3em;
        text-transform: uppercase;
    }
    a {
        text-decoration: underline;
        font-style: italic;
        color: $red;
    }
}

.btnTopics {
    margin-top: 1em;
    margin-bottom: 3em;
}
