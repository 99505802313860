.nav {
    position: relative;
}

.hamburger {
    align-self: center;
    justify-self: end;
    align-items: center;
    display:grid;
    grid-template-rows: 10px 10px 10px;
    grid-row-gap: 2px;
    line-height: 0;
    width: 50px;

    &:hover {
        cursor: pointer;
    }
}

.burger {
    background-color: $black;
    height: 4px;
    border-radius: 2px;
    display: inline-block;
}

.outer-burger {
    width: 30px;
    justify-self: end;
}

.inner-burger {
    width: 20px;
    justify-self: end;
}

.navbar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: .5em 1em;
    max-width: 1200px;
    margin: 0 auto;
}

.dropdown-wrapper {
    padding: 2em 1.5em;

    @include for-tablet-landscape-up {
        display: none;
    }
}

.nav-dropdown {
    position: absolute;
    width: 100%;
    top: 100%;
    background-color: $blue;
    max-height: 0px;
    overflow: hidden;
    z-index: 100;

    h2 {
        padding: 1.5em 0;
        color: $white;
        font-size: 1.1em;
    }

    a {
        display: block;
        color: $white;
        font-size: 2em;
        text-decoration: none;
    }

    a:hover {
        color: $red;
    }

}

.nav-signup, .nav-signup-DT {
    color: $white;

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $gray;
        font-style: italic;
        font-weight: 200;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $gray;
        font-style: italic;
        font-weight: 200;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: $gray;
        font-style: italic;
        font-weight: 200;
    }
}

.nav-signup .input-group,.nav-signup-DT .input-group {
    width: 100%;
    margin: 0 auto;
    max-width: 900px;
    display: grid;
    grid-template-columns: 6fr 6em;
    grid-template-rows: auto 2px;
    margin-bottom: 3em;

}

.nav-signup input, .nav-signup-DT input {
    background-color: rgba(0,0,0,0);
    border-width: 0px;
    color: $white;
    font-weight: 200;
    width: 100%;
    line-height: 2em;
    outline: none;
    caret-color: $grey;
}


#navBtnSignup {
    background-color: rgba(0,0,0,0);
    border: none;
    color: $red;
    font-weight: 200;
    font-size: .9em;
    text-transform: uppercase;
    letter-spacing: .1em;
    line-height: 2em;
    width: auto;
    cursor: pointer;

}

.nav-red-submit {
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    &:hover {
        opacity: .7;
        cursor: pointer;
    }
}

.nav-arrow-icon {
    display: inline-block;
    width: 16px;
    height: auto;
    transform: scaleX(-1);
    align-self: center;
    cursor: pointer;
}

.nav-wide-line {
    display: block;
    background-color: $grey;
    min-height: 1px;
}

.dropdown-wrapper-DT {
    display: none;
    grid-template-columns: 1fr 1fr;
    padding: 4em 8em 2em;

    @include for-tablet-landscape-up {
        display: grid;
    }
}

.nav-blurb {
    font-style: italic;
    color: $light_blue;
    margin-bottom: 2em;
}

.nav-links-DT {
    a {
        margin-bottom: 1em;
    }
}

.our-cats {
    margin-bottom: 2em;
    a {
        margin: 0;
    }
}

.about-link {
    display: block;
    margin-bottom: 1em;
}

.drop-cats {
    max-height: 0;
    overflow: hidden;
    a {
        font-size: 1.2em;
        padding: 1em;
    }
}

.nav-error-message, .nav-error-message-DT {
    display:block;
    color: $red;
    margin: 1em 0;
}

.nav-success-message, .nav-success-message-DT {
    display:none;

    h2 {
        color: $red;
    }
    p {
        color: $white;
        font-style: italic;
        font-weight: 200;
    }
}

.nav-signup-form {
    padding-top: 2em;
}

.svg-logo {
    width: 175px;
    height: auto;
    @include for-tablet-landscape-up {
        width: 200px;
    }
}

.dropcat-title {
    display:flex;
    height: 2em;
    align-items: center;
    cursor: pointer;
    &:hover {
        .caret {
            fill: $red;
        }
        .oc-title {
            color: $red;
        }
    }
}
.caret {
    height:25px;
    width: auto;
    fill: $white;
    align-self: center;
    justify-self: center;
    padding-left: 10px;
    padding-top: 5px;
}
.flip-caret {
    transform: rotateX(180deg);
}
.carety {
    display: inline-block;
}

.the-x {
    display:none;
    height:25px;
    width: 25px;
    align-self: center;
    justify-self: end;
    align-items: center;

    &:hover {
        cursor: pointer;
        fill: $red;
    }
}
