.brands {
    max-width: 100%;
    background-image: url('/site/themes/reviewtheme/img/wide_bluegradient_flip.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: $white;
    padding: 1.5em;
}

.brands h2 {
    margin-bottom: 1em;
}

.brands p {
    margin-bottom: 1.5em;
}

.brands-logos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 1em;
}
