.footer {
    margin-top: 2em;
    text-align: center;
}

.footer-links a {
    display: block;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    margin: 1em auto;
    @include for-tablet-landscape-up {
        display: inline-block;
        padding: 0 1em;
    }
}

.footer p {
    padding-bottom: 2em;
}
