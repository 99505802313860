.categories {
    padding: 1.5em;
}

.categories h2 {
    margin-bottom: 1em;
}

.categories ul {
    list-style: none;
}
