//Colors
$blue : #162345;
$dark_blue : #0f1937;
$grey: #d7d8da;
$dark_grey: #9a9a9a;
$light_blue : #3abfc4;
$off_white : #f1f2f4;
$red : #ff2f47;
$white : #fff;
$black : #000;

$gray: $grey;
$dark_gray: $dark_grey;

//Fonts
$font_sans : "Apercu", sans-serif;
$font_label : "interface", sans-serif;

//display
$h1_spacing: 0.06em;
$h2_spacing: 0.035em;
$h3_spacing: 0.3em;
