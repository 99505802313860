button {
    background: $red;
    border: 1px solid $red;
    border-radius: 50px;
    color: $white;
    font-size: .8em;
    font-weight: 400;
    letter-spacing: .2em;
    padding: 1em 2em;
    text-transform: uppercase;
}

.button {
    background: $red;
    border: 1px solid $red;
    border-radius: 50px;
    color: $white;
    font-size: .8em;
    font-weight: 400;
    letter-spacing: .2em;
    padding: 1em 2em;
    max-width: 250px;
    margin: 0 auto;
    text-transform: uppercase;
}

.buttontxt {
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
}

.flashybutton{
    position: relative;
    display: block;
    background: $red;
    color: $white;
    width: 200px;
    height: 40px;
    border: 0;
    font-size: .8em;
    font-weight: 400;
    letter-spacing: .2em;
    border-radius: 20px;
    transition: .6s;
    overflow: hidden;
    text-transform: uppercase;
    margin: 2em auto;
    &:focus{
        outline: 0;
    }
    &:before{
        content: '';
        display: block;
        position: absolute;
        background: rgba(255,255,255,0.5);
        width: 60px;
        height: 100%;
        left: 0;
        top: 0;
        opacity: .5;
        filter: blur(30px);
        transform: translateX(-100px)  skewX(-15deg);
    }
    &:after{
        content: '';
        display: block;
        position: absolute;
        background: rgba(255,255,255,0.2);
        width: 30px;
        height: 100%;
        left: 30px;
        top: 0;
        opacity: 0;
        filter: blur(5px);
        transform: translateX(-100px) skewX(-15deg);
    }
    &:hover{
        background: $light_blue;
        cursor: pointer;
        &:before{
            transform: translateX(300px)  skewX(-15deg);
            opacity: 0.6;
            transition: .7s;
        }
        &:after{
            transform: translateX(300px) skewX(-15deg);
            opacity: 1;
            transition: .7s;
        }
    }
}

.button-container {
    text-align: center;
    margin: 20px auto;
}
