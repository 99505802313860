.hero {
    color: $white;
}

.photo > img {
    max-width: 100%;
    height: auto;
}

.headline {
    width: 100%;
    background-image: url('/site/themes/reviewtheme/img/BlueBkgMB.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.content-wrap {
    padding: 1.2em;
}

.content-wrap > h1 {
    margin-bottom: 1.1em;
    margin-top: 1.1em;
}

.content-wrap > button{
    margin-bottom: 1.5em;
}
