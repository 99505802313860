.review-wrapper {
    display:grid;
    grid-template-columns: 1fr;
    padding: 1em 1.5em;
    color: $blue;
    max-width: 1100px;
    margin: 0 auto;

    h2 {
        text-align: left;
        margin: 1em 0 .5em;
    }

    p {
        text-align: left;
    }
    @include for-desktop-up {
        grid-template-columns: 3fr 1fr;
        grid-column-gap: 2em;
    }

}
.review-wrapper .main {
    grid-column: 1;
}


.review-hero {
    background-image: url('/site/themes/reviewtheme/img/BlueBkgMB.png');
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 3em;
    color: $white;
    font-weight: 300;

    h1 {
        margin-bottom: .5em;
        font-size: 1.7em;
        font-weight: 400;
        text-transform: capitalize;
    }

    h3 {
        color: $light_blue;
        margin: 0 auto 1em;
        font-size: .8em;
        font-weight: 700;
    }

    p {
        max-width: 768px;
        letter-spacing: .05em;
        color: $white;
    }

    @include for-tablet-landscape-up {
        background-image: url('/site/themes/reviewtheme/img/BlueDT.png');
    }
}

.mobile {
    grid-column: 1;
    text-align: center;
    img {
        width: 100%;
        max-width: 350px;
        height: auto;
    }
    @include for-tablet-landscape-up {
        display:none;
    }
}

.desktop {
    display:none;
    img {
        width: 100%;
        height: auto;
    }
    @include for-desktop-up {
        display: block;
        grid-column: 2;
    }
}

.great-for {
    margin-bottom: 2em;
    margin-top: 2em;
}

.bullet-grid {
    display: grid;
    grid-template-columns: 23px 1fr;
    align-items: start;
    grid-row-gap: 1em;

    p {
        margin: 0;
        padding: 0 1em;;
        text-align: left;
    }
}

.bullet-grid > img {
    object-fit: cover;
    width: 100%;
    max-height: 100%;
}

.bonus {
    margin-bottom: 2em;
}

.rating-section {
    width: 100%;
    background-color: $off_white;
    text-align: center;
    padding-bottom: 2em;
    color: $blue;
    h2 {
        padding: 1.5em 0 0.3em;
    }
    h4 {
        color: $red;
        font-size: 2em;
    }
    h3 {
        color: $red;
        font-size: .8em;
        font-weight: 700;
        letter-spacing: .2em;
        margin: .5em auto;
    }
    .rating-guide {
        margin-bottom: 1.5em;
    }
}
.rating-container {
    padding: 0 2em;
    max-width: 1200px;
    margin: 0 auto;
    h2 {
        text-transform: capitalize;
    }
}

.rating-grid {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 1000px;
    margin: 0 auto;
    .line {
        background-color: $grey;
        margin: 1em 0;
        height: 1px;
        @include for-tablet-landscape-up {
            height:auto;
            width: 1px;
            margin: 0 1em;
        }
    }
    p {
        font-size: .9em;
    }
    @include for-tablet-landscape-up {
        grid-template-columns: 1fr auto 1fr auto 1fr auto 1fr;
    }
}

#brand-name, #rating-label {
    display: inline-block;
}
#brand-name {
    padding-right: .3em;
}

.testimonials {
    margin: 0 auto;
    max-width: 900px;
    padding-bottom: 2em;
}

.testimonials h2 {
    margin: 1em auto 0.5em;
    text-align: center;
}

.carousel {
    background-color: $white;
    margin: 1.2em auto 1.5em;
}

.testimonial-card {
    color: $light_blue;
    text-align: center;
    margin: 1em auto;
    p {
        font-weight: 400;
        padding: 0 2em 1em;
        font-size: 1.1em;
        color: $light_blue;
    }
    a {
        display: inline-block;
        padding: 0 2em;
        text-align: center;
    }
}

.author {
    color: $dark_blue;
    font-style: italic;
    text-decoration: none;
    font-weight:400;
    &:hover {
        color: $red;
    }
}

.carousel-cell {
  background-color: $white;
  width: 80%;
  margin-right: 125px;
  counter-increment: carousel-cell;
}

.default-banner-vertical {
    text-align: center;
    background-color: $blue;
    padding: 1em;

    p {
        text-align: center;
        color: $white;
    }
    h2 {
        text-align: center;
        padding-top: 1em;
        color: $white;
    }

    a {
        display: block;
        text-decoration: none;
        text-align: center;
        max-width: 200px;
    }
    img {
        width: 80%;
        max-width: 175px;
        text-align: center;
        background-color: $white;
        padding: 1em;
        margin: 0 auto;
    }
}

.default-banner-horizontal {
    max-width: 460px;
    text-align: center;
    margin: 0 auto;
    background-color: $blue;
    padding: 1em;
    margin: 1em auto 0;

    p {
        text-align: center;
        color: $white;
    }
    h2 {
        text-align: center;
        padding-top: 1em;
        color: $white;
    }

    a {
        display: block;
        text-decoration: none;
        text-align: center;
        max-width: 200px;
    }
    img {
        max-width: 225px;
        text-align: center;
        background-color: $white;
        padding: 1em;
    }

}

.banner-mb {
    padding-top: 1em;
}
