.starset {
    width: 125px;
    height: auto;
    margin: 1em auto;
}

// CSS/SVG Star Ratings

$ratingSize: 100px;

.star-rating {
  height: ($ratingSize/5);
  position: relative;
  width: $ratingSize;
  margin: 2em auto .7em;
  align-self: center;
  &:before { // inactive
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgOTYgOTYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDk2IDk2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2ZpbGw6I0Q3RDhEQTt9PC9zdHlsZT48dGl0bGU+QXJ0Ym9hcmQgMSBjb3B5PC90aXRsZT48cGF0aCBjbGFzcz0ic3QwIiBkPSJNODkuMSw0MC4xTDY5LjYsNTlsNC42LDI2LjhjMC4xLDAuNi0wLjEsMS4xLTAuNiwxLjVjLTAuNSwwLjMtMS4xLDAuNC0xLjYsMC4xTDQ4LDc0LjdMMjQsODcuNGMtMC4yLDAuMS0wLjUsMC4yLTAuNywwLjJjLTAuMywwLTAuNi0wLjEtMC45LTAuM2MtMC41LTAuMy0wLjctMC45LTAuNi0xLjVMMjYuNCw1OUw3LDQwLjFjLTAuNi0wLjYtMC42LTEuNSwwLTIuMWMwLjItMC4yLDAuNS0wLjQsMC45LTAuNGwyNi45LTMuOWwxMi0yNC4zYzAuNC0wLjcsMS4zLTEsMi4xLTAuNkM0OSw4LjgsNDkuMiw5LDQ5LjQsOS4zbDEyLDI0LjNsMjYuOSwzLjljMC44LDAuMSwxLjQsMC45LDEuMywxLjdDODkuNSwzOS42LDg5LjMsMzkuOSw4OS4xLDQwLjFMODkuMSw0MC4xeiIvPjwvc3ZnPg==");
    background-repeat: repeat-x;
    background-size: ($ratingSize/5);
    bottom: 0;
    content: "";
    display: block;
    height: ($ratingSize/5);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: $ratingSize;
  }
  > span {
    display: block;
    text-indent: -10000px;
    width: $ratingSize;
    height: ($ratingSize/5);
    position: absolute;
    overflow: hidden;
    &:before { // active
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgOTYgOTYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDk2IDk2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2ZpbGw6IzE2MjM0NTt9PC9zdHlsZT48dGl0bGU+QXJ0Ym9hcmQgMSBjb3B5PC90aXRsZT48cGF0aCBjbGFzcz0ic3QwIiBkPSJNODkuMSw0MC4xTDY5LjYsNTlsNC42LDI2LjhjMC4xLDAuNi0wLjEsMS4xLTAuNiwxLjVjLTAuNSwwLjMtMS4xLDAuNC0xLjYsMC4xTDQ4LDc0LjdMMjQsODcuNGMtMC4yLDAuMS0wLjUsMC4yLTAuNywwLjJjLTAuMywwLTAuNi0wLjEtMC45LTAuM2MtMC41LTAuMy0wLjctMC45LTAuNi0xLjVMMjYuNCw1OUw3LDQwLjFjLTAuNi0wLjYtMC42LTEuNSwwLTIuMWMwLjItMC4yLDAuNS0wLjQsMC45LTAuNGwyNi45LTMuOWwxMi0yNC4zYzAuNC0wLjcsMS4zLTEsMi4xLTAuNkM0OSw4LjgsNDkuMiw5LDQ5LjQsOS4zbDEyLDI0LjNsMjYuOSwzLjljMC44LDAuMSwxLjQsMC45LDEuMywxLjdDODkuNSwzOS42LDg5LjMsMzkuOSw4OS4xLDQwLjFMODkuMSw0MC4xeiIvPjwvc3ZnPg==");
      background-repeat: repeat-x;
      background-size: ($ratingSize/5);
      bottom: 0;
      content: "";
      display: block;
      height:($ratingSize/5);
      left: 0;
      position: absolute;
      right: 0;
      text-indent: 10000px;
      top: 0;
    }
  }

}

.star-mobile {
    padding-top: 1em;
}
