.loading-deal {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    img {
        width: 200px;
        height: auto;
    }
}
