//Fonts
@font-face {
    font-family: Apercu;
    src: url('/site/themes/reviewtheme/fonts/Apercu Regular.otf');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: Apercu;
    src: url('/site/themes/reviewtheme/fonts/Apercu Light.otf');
    font-style: normal;
    font-weight: 200;
}

@font-face {
    font-family: Apercu;
    src: url('/site/themes/reviewtheme/fonts/Apercu Bold.otf');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: Apercu;
    src: url('/site/themes/reviewtheme/fonts/Apercu Italic.otf');
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: Apercu;
    src: url('/site/themes/reviewtheme/fonts/Apercu Light Italic.otf');
    font-style: italic;
    font-weight: 200;
}

body, h1, h2, h3, h4, h5, h6, p, li {
    font-family: $font_sans;
    font-weight: 200;
    margin: 0 auto;
}

ol, li {
    color: $dark_blue;
}

li {
    margin: 1em auto;
    line-height: 1.5em;
}

h1 {
    letter-spacing: $h1_spacing;
    line-height: 1.3em;
    font-family: Apercu;
    font-weight: 200;
}

h2 {
    line-height: 1.3em;
    letter-spacing: $h2_spacing;
    color: $dark_blue;
    font-weight: 400;
}

h3 {
    letter-spacing: $h3_spacing;
    color: $dark_blue;
    text-transform: uppercase;
    font-weight: 600;
}

p {
    line-height: 1.5em;
    color: $dark_blue;
}
